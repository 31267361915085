import React from 'react'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <a href="https://serverless.jagoreact.com" className="serverless-link">E-Book Serverless (New)</a>
                {/* <a href="https://blog.jagoreact.com" className="blog-link">Blog</a> */}
                <a href="https://member.jagoreact.com" className="login-member-area">Member Area →</a>
                <div className="inner">
                    <span className="icon major fa-react"></span>
                    <h1><strong>{`JagoReact.com`} </strong></h1>
                    <h2>
                        Jago Membuat Aplikasi Web Modern dengan <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer">React</a> dan <a href="https://firebase.google.com" target="_blank" rel="noopener noreferrer">Firebase</a>.</h2>
                    <p>Sebuah step-by-step <strong>Video Tutorial</strong> berbahasa Indonesia yang akan melatih anda <strong>menjadi seorang developer aplikasi web modern yang handal</strong>.</p>
                    <p>
                        Disajikan secara <strong>sederhana</strong>, namun tetap <strong>up-to-date</strong> dengan standar dan perkembangan terbaru baik itu di ekosistem React maupun Firebase.
                     </p>
                    <ul className="actions">
                        <li>
                            <a href="#download" className="button scrolly">Download Sekarang</a></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Header
