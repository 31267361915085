import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                {/* <p className="trademark">All Trademarks And Logos Are The Property Of Their Respective Owners. This Site And The Products And Services Offered On This Site Are Not Associated, Affiliated, Endorsed, Or Sponsored By Any Business Listed On This Page Nor Have They Been Reviewed Tested Or Certified By Any Other Company Listed On This Page.</p> */}
                <p>Ikuti perkembangan terbaru JagoReact.com di Social Media:</p>
                <ul className="icons">
                    <li><a href="https://twitter.com/jagoreact" target="_blank" rel="noopener noreferrer" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                    <li><a href="https://facebook.com/jagoreact" target="_blank" rel="noopener noreferrer" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                    <li><a href="https://instagram.com/jagoreact" target="_blank" rel="noopener noreferrer" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                    <li><a href="https://github.com/jagoreact" target="_blank" rel="noopener noreferrer" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
                    <li><a href="mailto:jagoreact@gmail.com" target="_blank" rel="noopener noreferrer" className="icon alt fa-envelope"><span className="label">Email</span></a></li>
                </ul>
                <ul className="copyright">
                    <li>&copy; {new Date().getFullYear()} by JagoReact.com</li>
                </ul>
            </section>
        )
    }
}

export default Footer
